import { setAlerts } from "./set-alerts";

export async function showAdd(parent: string, onShow?: () => Promise<void>, addFormName = "#add-form") {
    $(`${parent} ${addFormName}`).removeClass("was-validated");

    $(`${parent} ${addFormName} [id*=input]`).val("");

    // do some custom clear if need be
    if (onShow) {
        try {
            await onShow();
        } catch (err: unknown) {
            if ((err as object).hasOwnProperty("message")) {
                setAlerts("danger", (err as { message: string }).message);
            } else {
                const error = err as JQuery.jqXHR<any>;
                setAlerts("danger", `${error.status} (${error.statusText}): ${error.responseText}`);
            }

            $(parent).modal("hide");
            return;
        }
    }
    // await onShow()

    $(parent).modal("show");
}

// Save Add
//
export async function saveAdd(
    url: string,
    prepareData?: (form: JQuery<HTMLFormElement>) => Promise<any[] | JQuery<HTMLElement>>,
    parent: string = "#add-dialog",
    dataTable: string = "#table",
    reload: boolean = true,
    addFormName: string = "#add-form",
    successMsg: string = "Change(s) successfully applied"
) {
    // { url, parent = '#add-dialog',  }

    const form = $(`${parent} ${addFormName}`) as JQuery<HTMLFormElement>;

    if (form[0].checkValidity() === false) {
        form.addClass("was-validated");
    } else {
        $(`${parent} #btn-add-save`).startSpin("Saving...");

        $(`${parent} ${addFormName} [id*=input]`).prop("readonly", true);

        $(`${parent} #btn-add-cancel`).prop("disabled", true);

        try {
            await $.ajax({
                method: "POST",
                url: url,
                data: prepareData
                    ? await prepareData(form)
                    : $(`${parent} ${addFormName} :input[value!='']`).serialize(),
            });

            if (reload) {
                const table = $(dataTable).DataTable();
                table.ajax.reload((jData) => table.columns.adjust().draw());
            }
            setAlerts("success", successMsg);
        } catch (err: unknown) {
            if ((err as object).hasOwnProperty("message")) {
                setAlerts("danger", (err as { message: string }).message);
            } else {
                const error = err as JQuery.jqXHR<any>;
                setAlerts("danger", `${error.status} (${error.statusText}): ${error.responseText}`);
            }
        } finally {
            $(parent).modal("hide");

            $(`${parent} #btn-add-save`).stopSpin("Save");

            $(`${parent} ${addFormName} [id*=input]`).prop("readonly", false);
            $(`${parent} #btn-add-cancel`).prop("disabled", false);

            ($(`${parent} ${addFormName}`)[0] as HTMLFormElement).reset(); // clear the form
        }
    }
}

// Edit
//
export async function saveEdit(
    url: string,
    prepareData?: (form: JQuery<HTMLFormElement>) => Promise<any[] | JQuery<HTMLElement>>,
    parent: string = "#edit-dialog",
    dataTable: string = "#table",
    reload: boolean = true,
    editFormName: string = "#edit-form",
    successMsg: string = "Change(s) successfully applied"
): Promise<unknown> {
    const form = $(`${parent} ${editFormName}`) as JQuery<HTMLFormElement>; // TODO: check this type
    let result: unknown;

    if (form[0].checkValidity() === false) {
        form.addClass("was-validated");
    } else {
        $(`${parent} #btn-edit-save`).startSpin("Saving...");

        $(`${parent} ${editFormName} [id*=input]`).prop("readonly", true);
        $(`${parent} #btn-edit-cancel`).prop("disabled", true);

        try {
            result = await $.ajax({
                method: "PUT",
                url: url,
                data: prepareData
                    ? await prepareData(form)
                    : $(`${parent} ${editFormName}`).serialize(), // changed during TS migration
                //data: data2
                // data: $('#edit-form').serialize()
            });

            if (reload) {
                const table = $(dataTable).DataTable();
                table.ajax.reload((jData) => table.columns.adjust().draw());
            }

            setAlerts("success", successMsg);
        } catch (err: unknown) {
            if ((err as object).hasOwnProperty("message")) {
                setAlerts("danger", (err as { message: string }).message);
            } else {
                const error = err as JQuery.jqXHR<any>;
                setAlerts("danger", `${error.status} (${error.statusText}): ${error.responseText}`);
            }
        } finally {
            $(parent).modal("hide");

            $(`${parent} #btn-edit-save`).stopSpin("Save");

            $(`${parent} ${editFormName} [id*=input]`).prop("readonly", false);
            $(`${parent} #btn-edit-cancel`).prop("disabled", false);

            ($(`${parent} ${editFormName}`)[0] as HTMLFormElement).reset(); // clear the form
        }
    }
    return result;
}

// Delete
//
export async function saveDelete(
    url: string,
    parent: string = "#delete-dialog",
    dataTable: string = "#table",
    reload: boolean = true,
    successMsg: string = "Change(s) successfully applied"
) {
    $(`${parent} #btn-delete`).startSpin("Saving...");

    $(`${parent} #btn-delete-cancel`).prop("disabled", true);

    try {
        await $.ajax({
            method: "DELETE",
            url: url,
        });

        if (reload) {
            const table = $(dataTable).DataTable();
            table.ajax.reload((jData) => table.columns.adjust().draw());
        }
        setAlerts("success", successMsg);
    } catch (err: unknown) {
        if ((err as object).hasOwnProperty("message")) {
            setAlerts("danger", (err as { message: string }).message);
        } else {
            const error = err as JQuery.jqXHR<any>;
            setAlerts("danger", `${error.status} (${error.statusText}): ${error.responseText}`);
        }
    } finally {
        $(parent).modal("hide");

        $(`${parent} #btn-delete`).stopSpin("Delete");

        $(`${parent} #btn-delete-cancel`).prop("disabled", false);
    }
}

export function processFormCheckBox(
    form: JQuery<HTMLFormElement>,
    name: string
): JQuery.NameValuePair[] {
    const data = form.serializeArray();
    if (!data.find((element) => element.name === name)) {
        data.push({
            name: name,
            value: "false",
        });
    }

    // Changed this to FormData during TS conversion

    // convert the data to a valid "form data" structure
    // const data2 = new FormData(); // Changed this to FormData during TS conversion
    // for (const el of data) {
    //     data2.append(el.name, el.value);
    // }

    // return data2;

    return data;
}
